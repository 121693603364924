import * as THREE from "three";
import { useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  MarchingCubes,
  MarchingCube,
  Environment,
  Cloud,
  Bounds,
} from "@react-three/drei";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Physics, RigidBody, BallCollider } from "@react-three/rapier";

const vec = new THREE.Vector3();

function MetaBall({ color, ...props }) {
  const api = useRef();
  useFrame((state, delta) => {
    api.current.applyImpulse(
      vec
        .copy(api.current.translation())
        .normalize()
        .multiplyScalar(delta * -0.05)
    );
  });
  return (
    <RigidBody
      ref={api}
      colliders={false}
      linearDamping={4}
      angularDamping={0.95}
      {...props}
    >
      <MarchingCube strength={0.35} subtract={6} color={color} />
      <BallCollider args={[0.1]} type="dynamic" />
    </RigidBody>
  );
}

function Pointer() {
  const ref = useRef();
  useFrame(({ mouse, viewport }) => {
    const { width, height } = viewport.getCurrentViewport();
    vec.set(mouse.x * (width / 2), mouse.y * (height / 2), 0);
    ref.current.setNextKinematicTranslation(vec);
  });
  return (
    <RigidBody type="kinematicPosition" colliders={false} ref={ref}>
      <MarchingCube strength={0.5} subtract={10} color="white" />
      <BallCollider args={[0.1]} type="dynamic" />
    </RigidBody>
  );
}

export default function App() {
  const [modalContent, setModalContent] = useState(null);
  const modals = {
    chi: {
      title: "Domande frequenti",
      body: (
        <>
          <h4>Cosa è un agente intelligente?</h4>
          <p>
            Sono sistemi in grado di risolvere problemi senza l'intervento di un
            essere umano.
          </p>
          <p>
            Gli agenti intelligenti sono programmati per prendere la migliore
            decisione per raggiungere un obiettivo, scelto la maggior parte
            delle volte dalla persona che li utilizza.
          </p>
          <p>
            A differenza dei programmi tradizionali, nei quali il programmatore
            scrive una serie di step che il computer deve seguire, gli agenti
            intelligenti sono in grado di trovare in autonomia gli step da
            realizzare per avvicinarsi il più possibile all'obiettivo.
          </p>
          <p>
            Sono perciò programmi in grado di adattarsi ai cambiamenti senza
            dovere essere aggiornati.
          </p>
          <h4>Ho mai usato un agente intelligente?</h4>
          <p>Sì.</p>
          <p>
            Gli agenti intelligenti sono parte della nostra quotidianità da
            anni.
          </p>
          <p>
            Le nostre app per ascoltare musica implementano agenti intelligenti
            con il compito di suggerirci canzoni che ci piaceranno in una
            particolare giornata o luogo (spesso riducendo i costi di royalti),
            i motori di ricerca usano agenti intelligenti che devono mostrarci
            pubblicità di cose che desideriamo in quel momento e i nostri
            navigatori usano agenti con l'obiettivo di trovare la strada più
            veloce, corta e a volte economica.
          </p>
          <p>
            Tuttavia il funzionamento della nostra società è anche ampiamente
            legato a degli agenti intelligenti: le banche usano agenti per
            approvare crediti o per rilevare in pochi minuti se qualcuno sta
            usando la tua carta di credito in modo diverso dal solito, altri
            agenti aiutano ricercatori a trovare vaccini e medicine in meno
            tempo, alcuni agenti imparano a riconoscere il tuo viso o la tua
            voce per sbloccare il tuo telefonino o accendere le luci di casa,
            mentre altri eliminano il rumore di sottofondo mentre chiami a un
            famigliare.
          </p>
          <h4>Che tipo di agenti intelligenti produce Vector AI?</h4>
          <p>
            Produciamo agenti intelligenti che aiutino a risolvere problemi
            legati allo sport e il fitness.
          </p>
          <h4>Quale sono i vostri progetti attuali?</h4>
          <p>
            <img
              src="superbilders-logo-big.png"
              onClick={() => window.open("https://superbilders.com/enroll/")}
              style={{ cursor: "pointer" }}
            />
          </p>
          <p>
            Superbilders vuole rivoluzionare la vita delle persone aiutandole a
            perdere peso con l'aiuto dell'automazione.
          </p>
          <p>
            Si tratta di un gioco che permette all'utente di mangiare quello che
            desidera, per poi vedere in tempo reale quando è il momento di
            fermarsi in base al loro metabolismo.
          </p>
        </>
      ),
    },
  };
  return (
    <>
      <Canvas camera={{ position: [0, 0, 5], fov: 25 }}>
        <ambientLight intensity={0} />
        <directionalLight intensity={0} />
        <directionalLight
          intensity={10}
          position={[-10, -10, -10]}
          color="black"
        />
        <Physics gravity={[0, 1, 0]}>
          <MarchingCubes
            resolution={64}
            maxPolyCount={20000}
            enableUvs={false}
            enableColors
          >
            <meshStandardMaterial vertexColors roughness={0} />
            <MetaBall color="red" position={[1, 1, 0.5]} />
            <MetaBall color="blue" position={[-1, -1, -0.5]} />
            <MetaBall color="green" position={[2, 2, 0.5]} />
            <MetaBall color="orange" position={[-2, -2, -0.5]} />
            <MetaBall color="hotpink" position={[3, 3, 0.5]} />
            <MetaBall color="aquamarine" position={[-3, -3, -0.5]} />
            <Pointer />
          </MarchingCubes>
        </Physics>
        <Cloud opacity={0.5} />
        <Environment files="adamsbridge.hdr" />
        <Bounds fit clip observe margin={1}>
          <mesh visible={false}>
            <boxGeometry />
          </mesh>
        </Bounds>
      </Canvas>
      <div id="vector-ai-logo">
        <img src="vector-ai-logo.png" />
      </div>
      <Modal
        show={modalContent !== null}
        onHide={() => setModalContent(null)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <Row>
              <div id="modal-title">{modalContent?.title}</div>
              <div id="close-modal" onClick={() => setModalContent(null)}>
                Chiudi
              </div>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent?.body}</Modal.Body>
      </Modal>
      <div id="vector-ai-tabs">
        <Tabs
          defaultActiveKey="chi"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab eventKey="chi" title="Cosa facciamo">
            <Card text="white">
              <Card.Body>
                Vector AI è un laboratorio di idee. Sperimentiamo con algoritmi
                di intelligenza artificiale per trovare soluzioni innovative nel
                mondo dello sport e il fitness. A questo scopo, produciamo un
                tipo di programma conosciuto come agente intelligente. Consulta{" "}
                <Button
                  variant="outline-light"
                  onClick={() => setModalContent(modals["chi"])}
                >
                  la nostra FAQ
                </Button>{" "}
                per saperne di più.
              </Card.Body>
            </Card>
          </Tab>
          <Tab eventKey="longer-tab" title="Chi siamo">
            <Card text="white">
              <Card.Body>
                <p>Direzione: Victor Osorio (AI specialist)</p>
                <p>Magnitudine: Eric Lacroix (Fitness specialist)</p>
              </Card.Body>
            </Card>
          </Tab>
          <Tab eventKey="profile" title="Contattaci">
            <Card text="white">
              <Card.Body>
                Vector AI Srl P.IVA 12781600015. Cso Mediterraneo 128,
                Torino (TO). EMAIL:{" "}
                <a
                  href="mailto:info@vector-ai.it"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  info@vector-ai.it
                </a>{" "}
                PEC:{" "}
                <a
                  href="mailto:vector-ai@pec.it"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  vector-ai@pec.it
                </a>
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
